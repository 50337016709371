var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.vType == 1
                    ? _vm.$t('voucherTypes.1')
                    : _vm.vType == 2
                    ? _vm.$t('voucherTypes.2')
                    : _vm.$t('voucherTypes.3'),"icon":"mdi-credit-card-fast-outline"}}),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","disabled":"","label":_vm.$t('voucher.voucherNumber')},model:{value:(_vm.editedItem.voucherNumber),callback:function ($$v) {_vm.$set(_vm.editedItem, "voucherNumber", $$v)},expression:"editedItem.voucherNumber"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[[_c('div',[_c('v-menu',{ref:"menu",attrs:{"hide-details":"","close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","outlined":"","dense":"","rules":_vm.rules,"label":_vm.$t(
                                                                'voucher.voucherDate'
                                                            ),"readonly":""},model:{value:(
                                                            _vm.editedItem.voucherDate
                                                        ),callback:function ($$v) {_vm.$set(_vm.editedItem, "voucherDate", $$v)},expression:"\n                                                            editedItem.voucherDate\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"hide-details":"","active-picker":_vm.activePicker,"max":new Date(
                                                            Date.now() -
                                                                new Date().getTimezoneOffset() *
                                                                    60000
                                                        )
                                                            .toISOString()
                                                            .substr(0, 10),"min":"1950-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.saveDate},model:{value:(
                                                        _vm.editedItem.voucherDate
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "voucherDate", $$v)},expression:"\n                                                        editedItem.voucherDate\n                                                    "}})],1)],1)]],2),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","persistent-hint":"","rules":_vm.rules,"items":_vm.currencies,"item-text":"currencySymbol","item-value":"currencyGuid","label":_vm.$t('voucher.currency')},on:{"change":_vm.currencyChange},model:{value:(_vm.currencyGuid),callback:function ($$v) {_vm.currencyGuid=$$v},expression:"currencyGuid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","outlined":"","hide-details":"","dense":"","disabled":_vm.isCurrencyMain,"rules":_vm.rules,"label":_vm.$t('voucher.currencyValue')},model:{value:(_vm.currencyValue),callback:function ($$v) {_vm.currencyValue=$$v},expression:"currencyValue"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('voucher.account'),"outlined":"","hide-details":"","dense":"","hide-selected":"","items":_vm.accounts,"item-text":"nameCode","item-value":"accountGuid","rules":_vm.rules},model:{value:(_vm.editedItem.accountGuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "accountGuid", $$v)},expression:"editedItem.accountGuid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('fiscalYear.fiscalYear'),"outlined":"","hide-details":"","dense":"","disabled":_vm.editedItem.voucherGuid !=
                                                null &&
                                                _vm.editedItem.voucherGuid !=
                                                    '00000000-0000-0000-0000-000000000000',"hide-selected":"","items":_vm.fiscalYears,"item-text":"title","item-value":"fiscalYearGuid","rules":_vm.rules},model:{value:(_vm.editedItem.fiscalYearGuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "fiscalYearGuid", $$v)},expression:"editedItem.fiscalYearGuid"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{attrs:{"hide-details":"","outlined":"","rows":"3","label":_vm.$t('voucher.notes')},model:{value:(_vm.editedItem.notes),callback:function ($$v) {_vm.$set(_vm.editedItem, "notes", $$v)},expression:"editedItem.notes"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary darken-1","outlined":"","dark":"","icon":""},on:{"click":_vm.addItem}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"overflow-y-auto",attrs:{"outlined":"","max-height":"330","elevation":"0"}},[_c('v-card-text',[_c('v-list',_vm._l((_vm.entries),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t(
                                                                        'voucher.account'
                                                                    ),"hide-details":"","outlined":"","dense":"","hide-selected":"","items":_vm.entryAccounts,"item-text":"nameCode","item-value":"accountGuid","rules":_vm.rules},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem($event)}},model:{value:(
                                                                    item.accountGuid
                                                                ),callback:function ($$v) {_vm.$set(item, "accountGuid", $$v)},expression:"\n                                                                    item.accountGuid\n                                                                "}})],1),(
                                                                _vm.vType ==
                                                                    3 ||
                                                                    _vm.vType ==
                                                                        1
                                                            )?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","hide-details":"","label":_vm.$t(
                                                                        'voucher.debit'
                                                                    ),"rules":_vm.rules,"outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem($event)}},model:{value:(
                                                                    item.debit
                                                                ),callback:function ($$v) {_vm.$set(item, "debit", $$v)},expression:"\n                                                                    item.debit\n                                                                "}})],1):_vm._e(),(
                                                                _vm.vType ==
                                                                    2 ||
                                                                    _vm.vType ==
                                                                        1
                                                            )?_c('v-col',{attrs:{"cols":"12","md":"2"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem($event)}}},[_c('v-text-field',{attrs:{"type":"number","min":"0","label":_vm.$t(
                                                                        'voucher.credit'
                                                                    ),"hide-details":"","rules":_vm.rules,"outlined":"","dense":""},model:{value:(
                                                                    item.credit
                                                                ),callback:function ($$v) {_vm.$set(item, "credit", $$v)},expression:"\n                                                                    item.credit\n                                                                "}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"outlined":"","dense":"","persistent-hint":"","items":_vm.sources,"hide-details":"","item-text":"key","item-value":"value","label":_vm.$t(
                                                                        'voucher.source'
                                                                    )},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem($event)}},model:{value:(
                                                                    item.sourceGuid
                                                                ),callback:function ($$v) {_vm.$set(item, "sourceGuid", $$v)},expression:"\n                                                                    item.sourceGuid\n                                                                "}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t(
                                                                        'notes'
                                                                    ),"outlined":"","dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addItem($event)}},model:{value:(
                                                                    item.notes
                                                                ),callback:function ($$v) {_vm.$set(item, "notes", $$v)},expression:"\n                                                                    item.notes\n                                                                "}})],1)],1)],1)],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                        var on = ref.on;
                                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red lighten-1"},on:{"click":function($event){return _vm.removeItem(
                                                                    i
                                                                )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("remove"))+" ")])])],1)],1)}),1)],1)],1)],1)],1)],1),_c('v-card-actions',[(_vm.isInRole(28))?_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary darken-1 white--text","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1):_vm._e(),(
                            _vm.isInRole(30) &&
                                _vm.editedItem.voucherGuid != null &&
                                _vm.editedItem.voucherGuid !=
                                    '00000000-0000-0000-0000-000000000000'
                        )?_c('v-btn',{staticClass:"mx-10",attrs:{"text":"","dark":"","color":"primary darken-1","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.print}},[_c('v-icon',[_vm._v("mdi-printer-outline")]),_vm._v(" "+_vm._s(_vm.$t("print"))+" ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mx-4",attrs:{"text":"","color":"red"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.removeItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }