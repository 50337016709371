<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="
                    vType == 1
                        ? $t('voucherTypes.1')
                        : vType == 2
                        ? $t('voucherTypes.2')
                        : $t('voucherTypes.3')
                "
                icon="mdi-credit-card-fast-outline"
            ></Page-Header>
            <!--Save-->
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card :loading="loading">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            hide-details
                                            outlined
                                            dense
                                            disabled
                                            v-model="editedItem.voucherNumber"
                                            :label="$t('voucher.voucherNumber')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    hide-details
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="
                                                                editedItem.voucherDate
                                                            "
                                                            :label="
                                                                $t(
                                                                    'voucher.voucherDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        hide-details
                                                        v-model="
                                                            editedItem.voucherDate
                                                        "
                                                        :active-picker.sync="
                                                            activePicker
                                                        "
                                                        :max="
                                                            new Date(
                                                                Date.now() -
                                                                    new Date().getTimezoneOffset() *
                                                                        60000
                                                            )
                                                                .toISOString()
                                                                .substr(0, 10)
                                                        "
                                                        min="1950-01-01"
                                                        @change="saveDate"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-select
                                            outlined
                                            dense
                                            hide-details
                                            persistent-hint
                                            :rules="rules"
                                            v-model="currencyGuid"
                                            :items="currencies"
                                            item-text="currencySymbol"
                                            item-value="currencyGuid"
                                            @change="currencyChange"
                                            :label="$t('voucher.currency')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            type="number"
                                            min="0"
                                            outlined
                                            hide-details
                                            dense
                                            :disabled="isCurrencyMain"
                                            :rules="rules"
                                            v-model="currencyValue"
                                            :label="$t('voucher.currencyValue')"
                                        ></v-text-field>
                                    </v-col>
                                    <!---------------------------------->
                                    <v-col cols="12" md="6">
                                        <v-autocomplete
                                            :label="$t('voucher.account')"
                                            v-model="editedItem.accountGuid"
                                            outlined
                                            hide-details
                                            dense
                                            hide-selected
                                            :items="accounts"
                                            item-text="nameCode"
                                            item-value="accountGuid"
                                            :rules="rules"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-autocomplete
                                            :label="$t('fiscalYear.fiscalYear')"
                                            v-model="editedItem.fiscalYearGuid"
                                            outlined
                                            hide-details
                                            dense
                                            :disabled="
                                                editedItem.voucherGuid !=
                                                    null &&
                                                    editedItem.voucherGuid !=
                                                        '00000000-0000-0000-0000-000000000000'
                                            "
                                            hide-selected
                                            :items="fiscalYears"
                                            item-text="title"
                                            item-value="fiscalYearGuid"
                                            :rules="rules"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    hide-details
                                    outlined
                                    rows="3"
                                    v-model="editedItem.notes"
                                    :label="$t('voucher.notes')"
                                ></v-textarea>
                            </v-col>
                            <!------------------------------------------------------------------------------------>
                            <v-col cols="12"
                                ><v-btn
                                    color="primary darken-1"
                                    outlined
                                    dark
                                    icon
                                    @click="addItem"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn></v-col
                            >
                            <v-col cols="12">
                                <v-card
                                    outlined
                                    class="overflow-y-auto"
                                    max-height="330"
                                    elevation="0"
                                >
                                    <v-card-text>
                                        <v-list>
                                            <v-list-item
                                                v-for="(item, i) in entries"
                                                :key="i"
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <v-row>
                                                            <v-col
                                                                cols="12"
                                                                md="3"
                                                            >
                                                                <v-autocomplete
                                                                    :label="
                                                                        $t(
                                                                            'voucher.account'
                                                                        )
                                                                    "
                                                                    hide-details
                                                                    v-model="
                                                                        item.accountGuid
                                                                    "
                                                                    outlined
                                                                    dense
                                                                    hide-selected
                                                                    :items="
                                                                        entryAccounts
                                                                    "
                                                                    item-text="nameCode"
                                                                    item-value="accountGuid"
                                                                    :rules="
                                                                        rules
                                                                    "
                                                                    v-on:keyup.enter="
                                                                        addItem
                                                                    "
                                                                >
                                                                </v-autocomplete>
                                                            </v-col>
                                                            <v-col
                                                                cols="12"
                                                                md="2"
                                                                v-if="
                                                                    vType ==
                                                                        3 ||
                                                                        vType ==
                                                                            1
                                                                "
                                                            >
                                                                <v-text-field
                                                                    type="number"
                                                                    min="0"
                                                                    hide-details
                                                                    v-on:keyup.enter="
                                                                        addItem
                                                                    "
                                                                    :label="
                                                                        $t(
                                                                            'voucher.debit'
                                                                        )
                                                                    "
                                                                    :rules="
                                                                        rules
                                                                    "
                                                                    outlined
                                                                    dense
                                                                    v-model="
                                                                        item.debit
                                                                    "
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col
                                                                cols="12"
                                                                md="2"
                                                                v-on:keyup.enter="
                                                                    addItem
                                                                "
                                                                v-if="
                                                                    vType ==
                                                                        2 ||
                                                                        vType ==
                                                                            1
                                                                "
                                                            >
                                                                <v-text-field
                                                                    type="number"
                                                                    min="0"
                                                                    :label="
                                                                        $t(
                                                                            'voucher.credit'
                                                                        )
                                                                    "
                                                                    hide-details
                                                                    :rules="
                                                                        rules
                                                                    "
                                                                    outlined
                                                                    dense
                                                                    v-model="
                                                                        item.credit
                                                                    "
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col
                                                                cols="12"
                                                                md="3"
                                                            >
                                                                <v-select
                                                                    outlined
                                                                    v-on:keyup.enter="
                                                                        addItem
                                                                    "
                                                                    dense
                                                                    persistent-hint
                                                                    v-model="
                                                                        item.sourceGuid
                                                                    "
                                                                    :items="
                                                                        sources
                                                                    "
                                                                    hide-details
                                                                    item-text="key"
                                                                    item-value="value"
                                                                    :label="
                                                                        $t(
                                                                            'voucher.source'
                                                                        )
                                                                    "
                                                                ></v-select>
                                                            </v-col>
                                                            <v-col
                                                                cols="12"
                                                                md="4"
                                                            >
                                                                <v-text-field
                                                                    :label="
                                                                        $t(
                                                                            'notes'
                                                                        )
                                                                    "
                                                                    v-on:keyup.enter="
                                                                        addItem
                                                                    "
                                                                    outlined
                                                                    dense
                                                                    v-model="
                                                                        item.notes
                                                                    "
                                                                    hide-details
                                                                >
                                                                </v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-btn
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="
                                                                    removeItem(
                                                                        i
                                                                    )
                                                                "
                                                                icon
                                                                color="red lighten-1"
                                                            >
                                                                <v-icon>
                                                                    mdi-delete-circle-outline
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>
                                                            {{ $t("remove") }}
                                                        </span>
                                                    </v-tooltip>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            v-if="isInRole(28)"
                            color="primary darken-1 white--text"
                            @click="save"
                            class="mx-4"
                            :disabled="!valid"
                            :min-width="100"
                        >
                            <v-icon>mdi-content-save-outline</v-icon>
                            {{ $t("save") }}
                        </v-btn>
                        <v-btn
                            v-if="
                                isInRole(30) &&
                                    editedItem.voucherGuid != null &&
                                    editedItem.voucherGuid !=
                                        '00000000-0000-0000-0000-000000000000'
                            "
                            class="mx-10"
                            text
                            dark
                            color="primary darken-1"
                            @click="print"
                            :disabled="!valid"
                            :min-width="100"
                        >
                            <v-icon>mdi-printer-outline</v-icon>
                            {{ $t("print") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="red" class="mx-4" @click="close">
                            {{ $t("cancel") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
            <!--Delete Entry popup-->
            <confirm-dialog
                :openDialog="dialogDelete"
                :onClicked="removeItemConfirm"
                :onClose="closeDelete"
                toolBarColor="red darken-2"
            ></confirm-dialog>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            fiscalYears: [],
            currencyValue: null,
            currencyGuid: null,
            billRemainingAmount: 0,
            id: this.$route.params.id,
            vType: this.$route.params.vType,
            billVoucher:
                this.$route.query.billVoucher != undefined
                    ? JSON.parse(this.$route.query.billVoucher)
                    : null,
            tempEntry: null,
            activePicker: null,
            date: null,
            menu: false,
            isCurrencyMain: false,
            voucherType: this.$route.params.vType,
            valid: true,
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            entryIndex: null,
            search: "",
            sourceTypes: [
                {
                    key: this.$t("none"),
                    value: 0
                },
                {
                    key: this.$t("bill.bill"),
                    value: 1
                },
                {
                    key: this.$t("customer.customer"),
                    value: 2
                }
            ],
            voucherSetting: null,
            vouchers: [],
            accounts: [],
            entryAccounts: [],
            currencies: [],
            sources: [],
            entries: [],
            editedItem: {
                voucherGuid: null,
                accountGuid: null,
                voucherNumber: null,
                voucherType: null,
                voucherDate: new Date()
                    .toJSON()
                    .slice(0, 10)
                    .replace(/-/g, "-"),
                notes: null,
                currencyValue: null,
                currencyGuid: null,
                sourceGuid: null,
                sourceType: null,
                vouchersSettingId: null,
                billGuid: null,
                isPaidBill: false,
                entries: []
            },
            defaultItem: {
                voucherGuid: null,
                accountGuid: null,
                voucherNumber: null,
                voucherType: null,
                voucherDate: new Date()
                    .toJSON()
                    .slice(0, 10)
                    .replace(/-/g, "-"),
                notes: null,
                currencyValue: null,
                currencyGuid: null,
                sourceGuid: null,
                sourceType: null,
                vouchersSettingId: null,
                billGuid: null,
                isPaidBill: false,
                entries: []
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        this.loading = true;
        this.getCurrencies();

        axios
            .get("FiscalYears/Get")
            .then(response => {
                this.fiscalYears = response.data.data;

                var activeFiscalYear = this.fiscalYears.find(
                    x => x.isActive == true
                );

                if (activeFiscalYear != undefined) {
                    this.editedItem.fiscalYearGuid =
                        activeFiscalYear.fiscalYearGuid;
                }
            })
            .catch(e => {
                this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
                console.log(e);
            })
            .finally(() => {
                this.loading = false;
            });
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = "DATE"));
        },
        $route() {
            //if (val != this.$route.name) {
            if (this.vType != this.$route.params.vType) {
                if (this.$route.params.vType == 2 && !this.isInRole(26))
                    this.redirectUnauthorizedUsers();
                else if (this.$route.params.vType == 3 && !this.isInRole(27))
                    this.redirectUnauthorizedUsers();

                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
                this.entries = [];
                this.id = null;
                this.vType = this.$route.params.vType;
                this.voucherType = this.$route.params.vType;
                this.billVoucher =
                    this.$route.query.billVoucher != undefined
                        ? JSON.parse(this.$route.query.billVoucher)
                        : null;
                this.refreshData();
            }
        },
        "editedItem.accountGuid": {
            handler: function(val) {
                this.entryAccounts = this.accounts.filter(function(obj) {
                    return obj.accountGuid != val;
                });

                if (this.entries != null)
                    for (let i = 0; i < this.entries.length; i++) {
                        if (this.entries[i].accountGuid == val) {
                            this.entries[i].accountGuid = null;
                        }
                    }
            },
            deep: true
        }
    },
    methods: {
        currencyChange(val) {
            if (val != null || val != undefined) {
                if (this.editedItem.voucherGuid == null) {
                    var x = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == val;
                    });

                    this.currencyValue = x[0].currencyValue;
                    this.isCurrencyMain = x[0].isMain;
                } else if (
                    this.editedItem.voucherGuid != null &&
                    this.editedItem.currencyGuid != val
                ) {
                    var voucherCurrency = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == val;
                    })[0];

                    this.currencyValue = voucherCurrency.currencyValue;
                    this.isCurrencyMain = voucherCurrency.isMain;
                } else {
                    var temp = this.editedItem.currencyGuid;
                    var c = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == temp;
                    });

                    this.currencyValue = this.editedItem.currencyValue;
                    this.isCurrencyMain = c[0].isMain;
                }
            }
        },
        generateVoucherNumber() {
            this.editedItem.voucherType = this.vType;
            this.editedItem.vouchersSettingId = this.voucherSetting.vouchersSettingId;

            axios
                .post("Vouchers/GenerateVoucherNumber", this.editedItem)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.editedItem.voucherNumber = response.data.data;
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshData();
                    console.log(e);
                });
        },
        getSettingsByType() {
            if (this.vType != undefined || this.vType != null)
                axios
                    .get("Vouchers/GetSettingsByType?type=" + this.vType)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.voucherSetting = response.data.data[0];
                            this.currencyGuid =
                                response.data.data[0].currencyGuid;

                            var temp = this.voucherSetting.currencyGuid;
                            var c = this.currencies.filter(function(obj) {
                                return obj.currencyGuid == temp;
                            });

                            this.currencyValue = c[0].currencyValue;
                            this.isCurrencyMain = c[0].isMain;

                            this.editedItem.accountGuid =
                                response.data.data[0].accountGuid;

                            this.editedItem.voucherType = this.vType;
                            this.editedItem.vouchersSettingId =
                                response.data.data[0].vouchersSettingId;

                            axios
                                .post(
                                    "Vouchers/GenerateVoucherNumber",
                                    this.editedItem
                                )
                                .then(response => {
                                    if (response.data.status == "Successful") {
                                        this.editedItem.voucherNumber =
                                            response.data.data;
                                    } else {
                                        this.$toast.error(
                                            this.$t(
                                                "error." + response.data.message
                                            )
                                        );
                                    }
                                })
                                .catch(e => {
                                    this.$toast.error(
                                        this.$t(
                                            "AnErrorOccurredDuringTheProcess"
                                        )
                                    );
                                    console.log(e);
                                });
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    });
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        addItem() {
            this.entries.push({ isHeader: false, debit: 0, credit: 0 });
        },
        removeItem(index) {
            this.entryIndex = index;

            if (
                this.entries[index].entryGuid == undefined ||
                this.entries[index].entryGuid ==
                    "00000000-0000-0000-0000-000000000000"
            )
                this.entries.splice(index, 1);
            else this.dialogDelete = true;
        },
        removeItemConfirm() {
            this.entries.splice(this.entryIndex, 1);
            this.dialogDelete = false;
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.entries = null;
                this.entries = [];
                this.refreshData();
            });

            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        getCurrencies() {
            axios
                .get("Currencies/Get")
                .then(response => {
                    this.currencies = response.data.data;
                    this.refreshData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        refreshData() {
            if (this.id) {
                axios
                    .get("Vouchers/Get/" + this.id)
                    .then(response => {
                        this.editedItem = response.data.data;
                        this.editedIndex = 0;
                        this.entries = this.editedItem.entries;

                        this.editedItem.voucherDate = moment(
                            this.editedItem.voucherDate
                        ).format("yyyy-MM-DD");

                        this.currencyGuid = this.editedItem.currencyGuid;
                        this.currencyValue = this.editedItem.currencyValue;

                        //select the main account
                        if (this.editedIndex != -1)
                            this.editedItem.accountGuid = this.entries.filter(
                                function(obj) {
                                    return obj.isHeader == true;
                                }
                            )[0].accountGuid;

                        //remove the main header
                        this.entries = this.entries.filter(function(obj) {
                            return obj.isHeader == false;
                        });
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.getSettingsByType();
            }

            axios
                .get("AccountsTree/Get")
                .then(response => {
                    this.accounts = response.data.data.filter(function(obj) {
                        return (
                            obj.parentGuid !=
                            "00000000-0000-0000-0000-000000000000"
                        );
                    });

                    var accountGuid = this.editedItem.accountGuid;
                    this.entryAccounts = this.accounts.filter(function(obj) {
                        return obj.accountGuid != accountGuid;
                    });
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Customers/Get")
                .then(response => {
                    var data = response.data.data;
                    const reshapedData = data.map(function(row) {
                        return {
                            key: row.fullName,
                            value: row.customerGuid
                        };
                    });

                    this.sources = reshapedData;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        save() {
            try {
                if (this.$refs.form.validate()) {
                    if (this.editedIndex == -1) {
                        this.editedItem.voucherGuid =
                            "00000000-0000-0000-0000-000000000000";

                        this.editedItem.vouchersSettingId = this.voucherSetting.vouchersSettingId;
                        this.editedItem.voucherType = this.voucherType;
                    }

                    //copy the displayed entries to voucher entries
                    this.editedItem.entries = [...this.entries];

                    if (this.billVoucher == null) {
                        var accountGuid = this.editedItem.accountGuid;
                        this.editedItem.entries.forEach(function(element) {
                            element.accountContraGuid = accountGuid;
                        });

                        //set the new header entry
                        this.editedItem.entries.push({
                            accountGuid: this.editedItem.accountGuid,
                            accountContraGuid: null,
                            isHeader: true,
                            sourceGuid: "00000000-0000-0000-0000-000000000000",
                            sourceType: 0
                        });
                    }

                    if (this.billVoucher != null) {
                        var entriesDebit = this.entries.reduce(
                            (a, b) => a + (b["debit"] || 0),
                            0
                        );

                        if (entriesDebit > this.billRemainingAmount) {
                            this.$toast.warning(
                                this.$t("error.CannotPayMoreThanRemaining")
                            );
                            return;
                        }

                        this.editedItem.entries.push(this.tempEntry);
                    }

                    if (this.editedItem.entries.length < 2) {
                        this.$toast.warning(this.$t("error.NoEntriesProvided"));
                        return;
                    }

                    if (this.editedItem.fiscalYearGuid == null) {
                        this.$toast.warning(
                            this.$t("error.NoFiscalYearSelected")
                        );
                        return;
                    }

                    this.editedItem.currencyGuid = this.currencyGuid;
                    this.editedItem.currencyValue = this.currencyValue;

                    axios
                        .post("Vouchers/Save", this.editedItem)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );

                                var routeName = "";

                                if (this.vType == 2)
                                    routeName = "voucher.receipt";
                                else if (this.vType == 3)
                                    routeName = "voucher.payment";

                                this.$router
                                    .push({
                                        name: routeName,
                                        params: {
                                            id:
                                                response.data.data[0]
                                                    .voucherGuid,
                                            vType: this.vType
                                        }
                                    })
                                    .catch(error => {
                                        error;
                                    });
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                            this.refreshData();
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.refreshData();
                            console.log(e);
                        });
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        print() {
            axios({
                url: "Vouchers/PrintVoucher",
                method: "GET",
                responseType: "blob",
                params: {
                    voucherGuid: this.editedItem.voucherGuid
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "سند.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        }
    }
};
</script>
